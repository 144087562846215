import React, { useState, useEffect, useContext } from "react";
import "src/scss/main.css";
import {
  Box,
  Typography,
  Grid,
  makeStyles,
  Button,
} from "@material-ui/core";
import Countdown from "react-countdown";
import styled from "styled-components";
import Logo from "src/component/Logo";
import { useHistory, Link as RouterComponent } from "react-router-dom";
import { calculateTimeLeft } from "./timer";
import TopBar from "src/layouts/LoginLayout/index.js";
// import ToastMessage from "src/component/CustonToast.js";
import Page from "src/component/Page";
import ToastMessage from "src/component/CustonToast.js";

// import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import ApiConfig from "src/connectors/config/ApiConfig.js";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import moment from "moment";
import { AuthContext } from "src/context/Auth";
import OtpInput from "react-otp-input";

const useStyles = makeStyles((theme) => ({
  logosec: {
    "@media(min-width:1280px)": {
      display: "none",
    },
  },
  textfiled: {
    width: "10px",
    height: "40px",
    background: "rgba(217, 217, 217, 0.1)",
    border: "1px solid #D4D4D4",
    borderRadius: "3px",
  },
  submit: {
    width: "374.3px",
    height: "50px",
    background: "#269000",
    marginLeft: "12.5%",
    borderRadius: "7px",
    marginBottom: "3rem",
    marginTop: "-1rem",
  },

  forgot: {
    display: "flex",
    justifyContent: "flex-end",
  },
  OtpDiv: {
    marginTop: "2rem",
    paddingBottom: "3rem",
    paddingLeft: "15px",
  },
  Link: {
    textDecoration: "none",
    fontSize: "18px",
    float: "right",
    paddingRight: "18px",
    marginBottom: "9px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: " 700",
    fontSize: "10px",
    lineHeight: "12px",
    letterSpacing: "0.06em",
    color: "#FF0000",
  },
  inputBlocks: {
    paddingLeft: "27px",
    PaddingTop: "45px",
  },
  inputFields: {
    color: "#ffff",
  },
  timer: {
    marginRight: "25px",
    paddingLeft: "11.5rem",
    color: "#ffff",
  },
  TimerExpired: {
    color: "#E03128",
    marginRight: "18px",
    paddingLeft: "13rem",
    cursor: "pointer",
  },
  timerGrid: {
    padding: "1rem 1.3rem",
    display: "flex",
    textAlign: "right",
    gap: "11rem",
    // color:"#FF0000",
  },
  error: {
    color: "#E03128",
  },
  inputBox: {
    paddingLeft: "13%",
    paddingTop: "2.5rem",
  },
}));
const FieldInput = styled.input`
  width: 40px;
  height: 40px;
  color: white;
  background: rgba(217, 217, 217, 0.1) !important;
  border: 1px solid #d4d4d4 !important;
  border-radius: 5px !important;
  border: 1px solid #333;
  text-align: center;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

function Login(props) {
  const history = useHistory();
  const auth = useContext(AuthContext);
  console.log("yi9huthfjfiog", auth);

  const classes = useStyles();
  const [timer, setTimer] = useState(false);
  const [isLoading, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [code, setCode] = useState("");

  const handleChange = (code) => {
    setCode(code);
    if (code.length == 6) {
      setError("");
    }
  };


  const [open, setOpen] = useState(false);
  const [type, setType] = useState(false);
  const [message, setMessage] = useState("");

  const handleClose = (x) => {
    setOpen(x);
  };


  const handleFormSubmit = async (values) => {
    console.log("values-----", values);
    const userEmail = localStorage.getItem("emailForgot");
    console.log(userEmail, "userEmail");
    console.log(values, "values");
    if (code.length==6){
      try {
        // setLoader(true);
  
        const res = await axios({
          method: "POST",
          url: "https://nodepune-flashloan.mobiloitte.com/api/v1/signUpOTPVerfiy",
  
          data: {
            email: userEmail,
            OTP: values,
          },
        });
  
        console.log(res, "otpverify");
  
        if (res.data.success) {
          setLoader(false);
          setType("success");
          setOpen(true);
          setMessage("OTP has been verified.");

          // toast.success("OTP has been verified.");
          // setLoader(false);
          setTimeout(() => {
            history.push("/Reset");
          }, 3000);
          // setTimeout(() => {history.push("/Reset");
          //   // history.push({
          //   //   pathname: "/Reset",
          //   //   state: { email: values.email, otp: values.otp },
          //   // });
          // }, 3000);
  
          // setIsSubmit(true);
          // localStorage.removeItem("values.email");
        } else {
          setType("error");
          setOpen(true);
          setMessage(res.data.message);

          // toast.error(res.data.message);
          setTimeout(() => {
            history.push("/verifyotp");
          }, 3000);
        }
      } catch (error) {
        setLoader(false);
        // if (error.response) {
          setType("error");
          setOpen(true);
          setMessage(error.response.data.message);
          // toast.error(error.response.data.message);
        // } else {
          setType("error");
          setOpen(true);
          setMessage(error.message);
          // toast.error(error.message);
        // }
      }
    }else{
      setError("Enter OTP");
    }
    }
 

  const handleResendOTP = async (values) => {
    console.log(values, "values");
    // setLoader(true);
    try {
      const res = await axios.post(
        "https://nodepune-flashloan.mobiloitte.com/api/v1/forgetPaswordSentOTP",
        {
          email: localStorage.getItem("emailForgot"),
        }
      );
      console.log(res.status, "api data");
      if (res.data.success) {
        // setLoader(false);
        setType("success");
        setOpen(true);
        setMessage("OTP sent successfully, Please check your email.");

        // toast.success("OTP sent successfully, Please check your email.");
        auth.setEndtime(moment().add(3, "m").unix());
        history.push("/verifyotp");
        setTimer(true);
        console.log(res.data.message);
      } else if (
        res.status === 400 &&
        res.data.message ===
          "OTP is Already Sent To Mail For Resend OTP Wait For 3 Min"
      ) {
        setType("warn");
        setOpen(true);
        setMessage("OTP is Already Sent To Mail For Resend OTP Wait For 3 Min");

        // toast.warn("OTP is Already Sent To Mail For Resend OTP Wait For 3 Min");
        // setIsLoading(false);
      }

      return res;
    } catch (error) {
      console.log(error);
      // setIsLoading(false);
      if (
        error.response.status === 400 &&
        error.response.data.message ===
          "OTP is Already Sent To Mail For Resend OTP Wait For 3 Min"
      ) {
        setType("error");
        setOpen(true);
        setMessage("OTP is Already Sent To Mail For Resend OTP Wait For 3 Min");

        // toast.error(
        //   "OTP is Already Sent To Mail For Resend OTP Wait For 3 Min"
        // );
        // setIsLoading(false);
      }

      // setBtnText("CREATE AN ACCOUNT");
    }
  };


  const handleChangeOTP = (e) => {
    const { maxLength, value, name } = e.target;
    console.log("maxLength", maxLength, value, name);
    const [fieldName, fieldIndex] = name.split("-");

    let fieldIntIndex = parseInt(fieldIndex, 10);

    // Check if no of char in field == maxlength
    if (value.length >= maxLength) {
      // It should not be last input field
      if (fieldIntIndex < 6) {
        // Get the next input field using it's name
        const nextfield = document.querySelector(
          `input[name=field-${fieldIntIndex + 1}]`
        );

        // If found, focus the next field
        if (nextfield !== null) {
          nextfield.focus();
        }
      }
    }
  };
  
  return (
    <>
    {calculateTimeLeft}
    <ToastMessage
        type={type}
        handleClose={handleClose}
        open={open}
        message={message}
      />
      {/* <div>
        <ToastContainer />
      </div> */}
      {calculateTimeLeft}
      <ToastMessage
        type={type}
        handleClose={handleClose}
        open={open}
        message={message}
      />
      {/* <div>
        <ToastContainer />
      </div> */}
      <TopBar>
        <Page title="forgot">
          <Grid
            className="d-flex height100"
            style={{ width: "auto", height: "auto" }}
          >
            <Box className="loginForm">
              <Box className="signupbox">
                <Grid
                  container
                  direction={"column"}
                  style={{ paddingLeft: "0.5rem" }}
                >
                  <Grid item style={{}}>
                    <Typography
                      variant="h5"
                      style={{
                        textAlign: "center",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "30px",
                        lineHeight: "24px",
                        color: "#000000",
                        paddingTop: "3rem",
                        paddingLeft: "2rem",
                      }}
                    >
                      Verification Code
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "18px",
                        textAlign: "center",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "12px",
                        color: "#343434",
                        marginTop: "10%",
                        paddingLeft: "40px",
                      }}
                    >
                      A 6- digit OTP has been sent to your registered e-mail.
                    </Typography>
                  </Grid>{" "}
                  <Grid item>
                    <Box className={classes.logosec}>
                      <Logo width="110" style={{ cursor: "pointer" }} />
                    </Box>
                  </Grid>
                  <Box className={classes.inputBox}>
                    <Grid item style={{}}>
                      <OtpInput
                        value={code}
                        onChange={handleChange}
                        numInputs={6}
                        separator={<span style={{ width: "1.2rem" }}></span>}
                        isInputNum={true}
                        shouldAutoFocus={true}
                        inputStyle={{
                          border: "1px solid #D4D4D4",
                          borderRadius: "5px",
                          width: "40px",
                          height: "40px",
                          fontSize: "20px",
                          color: "#00000",
                          fontWeight: "400",
                          caretColor: "#0000",
                          background: "rgba(217, 217, 217, 0.1)",
                        }}
                        focusStyle={{
                          border: "2px solid #333",
                          outline: "none",
                        }}
                      />
                    </Grid>
                  </Box>





                    {auth.timeLeft && auth.timeLeft.seconds >= 0 ? (
                      <>
                       <Grid xs={12} className={classes.timerGrid}>
                        <Grid xs={6} >
                        <Typography className={classes.error}>{error}</Typography></Grid>
                        <Grid xs={6}>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#000000",
                              // fontSize: "16px",
                              // fontWeight: 500,
                              // paddingLeft: "1rem",
                            }}
                          >
                            {" "}
                            {auth.timeLeft?.minutes} : {auth.timeLeft?.seconds}
                          </Typography>{" "}
                        </Grid>
                        </Grid>
                      </>
                    ) : (
                      <>
                      <Grid xs={12} className={classes.timerGrid}>
                      <Grid xs={6} >
                      <Typography className={classes.error}>{error}</Typography>
                      </Grid>
                      <Grid xs={6} >
                      <Typography
                        variant="contained"
                        color="secondary"
                        fullWidth
                        style={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontSize: "18px",
                          cursor:"pointer",
                          color: "#FF0000",

                        }}
                        onClick={handleResendOTP}
                        disabled={auth.timeLeft && auth.timeLeft.seconds > 0}
                      >
                        {" "}
                        Resend OTP{" "}
                      </Typography>{" "}
                      </Grid>
                      </Grid>
                      </>
                    )}
                
         
                 





                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                    type="submit"
                    disabled={isLoading}
                    onClick={() => handleFormSubmit(code)}
                  >
                    Submit
                    {/* {isLoading && <ButtonCircularProgress />} */}
                  </Button>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Page>
      </TopBar>
    </>
  );
}

export default Login;
