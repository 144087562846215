import React, { createContext, useState, useEffect, Fragment } from "react";
import axios from "axios";
import { useHistory, Redirect } from "react-router-dom";
import { calculateTimeLeft } from "../views/Auth/forget-password-link/timer.js";
export const AuthContext = createContext();

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData] = useState({});
  const history = useHistory();

  const [endTime, setEndtime] = useState();

  const [timeLeft, setTimeLeft] = useState();

  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });





  const setSession = (accessToken) => {
    if (accessToken) {
      console.log("chala");
      localStorage.setItem("creatturAccessToken", accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem("creatturAccessToken");
      delete axios.defaults.headers.common.Authorization;
    }
    setTimeout(() => {
      localStorage.removeItem("creatturAccessToken");
      const accessToken = window.localStorage.getItem("creatturAccessToken");
      if (!accessToken){
        return <Redirect to="/" />;
      }
      
    }, 3600000);
  };

  function checkLogin(type) {
    console.log("chalja");
    const accessToken = window.localStorage.getItem("creatturAccessToken");

    return accessToken ? true : false;
  }
  console.log(isLogin, "isLogin");
  let data = {
    userLoggedIn: isLogin,
    userData,
    setEndtime,
    setTimeLeft,
    timeLeft,
    endTime,
    // setSession,
    userLogIn: (data, type) => {
      setSession(data);
      setIsLogin(type);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
