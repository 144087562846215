import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  MenuItem,
  Box,
  Container,
  Menu,
  Grow,
  Paper,
  Popper,
  MenuList,
} from "@material-ui/core";
import styled from "styled-components";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
// import Logo from "./../../component/Logo";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Scroll from "react-scroll";
const ScrollLink = Scroll.Link;
const headersData = [
  {
    label: "HOME",
    href: "/",
    scrollID: "section1",
  },
  {
    label: "PRICING",
    href: "/",
    scrollID: "section5",
  },
  {
    label: "ABOUT",
    href: "/",
    scrollID: "section3",
  },
];

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginTop: "1%",
    fontFamily: "'Inter'",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "17px",
    color: "#FFFFFF",
    borderRadius: "0px",
    textAlign: "center",
    marginLeft: "12px",
    height: "31px",
    padding: "30px 10px",
    letterSpacing: "0px",
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#FFF",
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&:active": {
      color: "#269000",
    },
  },

  toolbar: {
    background: "transparent",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "17px",
    fontSize: "20px",
    display: "flex",
    height: "98px",

    "@media (max-width: 900px)": {
      paddingLeft: "544px",
      height: "100%",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    textAlign: "center",
    width: "50px",
    marginBottom: "30px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#269000",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "0px",
    right: "-10px",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    // height: '44.5px',
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },
  menuMobile: {
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "normal",
    letterSpacing: "-0.6px",
    lineHeight: "1.75",
    color: "#fff",

    padding: "10px",
    "@media (max-width: 500px)": {
      width: "100%",
    },
  },
  paper1: {
    background: "black",
    color: "white",
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    marginTop: "15px",
    padding: "0px",
  },
  search: {
    height: "31px",
    position: "relative",
    color: "#ABABAB",
    borderRadius: "100px",
    backgroundColor: "#E6E6E6",
    border: "1px solid #fff",
    "&:hover": {
      backgroundColor: "#ececec",
      border: "1px solid #300760",
    },
    marginLeft: 20,
    width: "140px",
    maxWidth: "257px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "180px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: theme.spacing(0, 2),
    color: "#000000",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "16px",
  },
  wallet: {
    marginLeft: "5px",
    paddingTop: "4px",
    "&:hover": {
      background: "transparent",
      color: "#fff",
      border: "3px solid #00AFFF",
    },
    "@media (max-width: 900px)": {
      marginLeft: "12px",
      marginTop: "12px",
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "13px",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100px",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  submenu: {
    borderTop: "3px solid #300760",
    top: "25px !important",
  },
  btn1: {
    paddingTop: "-1%",
    width: "118.66px",
    height: "47.28px",
    background: ` linear-gradient(180deg, #269000 0%, #383838 100%)`,
    borderRadius: " 5px",
    marginLeft: "20%",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "15px",
    lineHeight: "20px",
    color: "#fff",
    border: "3px solid #269000",
    "&:hover": {
      background: "transparent",
      color: "#fff",
      border: "3px solid #269000",
    },
  },
  btn2: {
    paddingTop: "-1%",
    width: "118.66px",
    height: "47.28px",
    background: ` linear-gradient(180deg, #269000 0%, #383838 100%)`,
    borderRadius: " 5px",
    marginLeft: "2.5%",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "15px",
    lineHeight: "20px",
    color: "#fff",
    border: "3px solid #269000",
    "&:hover": {
      background: "transparent",
      color: "#fff",
      border: "3px solid #269000",
    },
  },
  btn4: {
    paddingTop: "-1%",
    width: "118.66px",
    height: "47.28px",
    background: "transparent",
    color: "#fff",
    border: "3px solid #269000",
    borderRadius: " 5px",
    // marginRight:'-112px',
    marginLeft: "2.5%",
    // padding: "1px 0px",

    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "21px",
  },
  btn3: {
    paddingTop: "-1%",
    width: "118.66px",
    height: "47.28px",
    background: "transparent",
    color: "#fff",
    border: "3px solid #269000",
    borderRadius: " 5px",
    // marginRight:'-112px',
    marginLeft: "23%",
    // padding: "1px 0px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "21px",
  },
}));
const LogoImgDiv = styled.div`
  display: flex;
  width: 100%;
  marginleft: -118px;
  max-width: 165px;
  @media (max-width: 500px) : {
    marginleft: 20%;
    width: 100px;
  }
`;
export default function Header() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const locations = useLocation();
  console.log(locations.pathname, "path");
  const {
    menuMobile,
    menuButton,
    menuButton1,
    toolbar,
    drawerContainer,
    drawericon,
    logoDrawer,
    containerHeight,
    mainHeader,
    wallet,
    submenu,
    btn2,
    btn1,
    btn3,
    btn4,
    box1,
  } = useStyles();
  const history = useHistory();
  console.log(history?.location);

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;
  const location = useLocation();
console.log(location,"location")
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const [open1, setOpen1] = useState({ community: false, user: false });
  const anchorRef = { community: useRef(null), user: useRef(null) };
  const [token, setToken] = useState("");
  const handleClose2 = (event, name) => {
    if (
      anchorRef[name].current &&
      anchorRef[name].current.contains(event.target)
    ) {
      return;
    }

    setOpen1({ ...open1, [name]: false });
  };

  function handleListKeyDown(event, name) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen1({ ...open1, [name]: false });
    }
  }

  const displayDesktop = () => {
    return (
      <Container maxWidth="lg" className="navbar">
        <Toolbar className={toolbar}>
          {femmecubatorLogo}
          {locations.pathname === "/term" || locations.pathname === "/policy" ? (
            ""
          ) : (
            <Grid
              container
              item
              direction="row"
              justify="flex-end"
              alignItems="center"
              style={{ paddingLeft: "0px" }}
            >
              {getMenuButtons()}

              <Popper
                open={open1.community}
                anchorEl={anchorRef.community.current}
                role={undefined}
                transition
                disablePortal
                className={submenu}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener
                        onClickAway={(e) => handleClose2(e, "community")}
                      >
                        <MenuList
                          autoFocusItem={open1}
                          id="menu-list-grow"
                          onKeyDown={(e) => handleListKeyDown(e, "community")}
                        >
                          <MenuItem component={Link} to="/fungy-token">
                            Fungy Token
                          </MenuItem>
                          <MenuItem component={Link} to="/faq">
                            Discussion
                          </MenuItem>
                          <MenuItem>Voting</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
              <Button
                    size="large"
                    className={locations.pathname === "/register" ? btn3 : btn1}
                    onClick={() => history.push("/register")}
                  >
                    Sign Up
                  </Button>
                  <Button
                    size="large"
                    className={locations.pathname === "/login" ? btn4 : btn2}
                    onClick={() => history.push("/login")}
                  >
                    Sign In
                  </Button>
            </Grid>
          )}
        </Toolbar>
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <img
                  className={logoDrawer}
                  src="images/sideBar.png"
                  width="100%"
                  alt=""
                />
              </Box>
            </Box>
            {token ? (
              <></>
            ) : (
              <>
                {" "}
                <Button
                  size="large"
                  className={wallet}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  component={Link}
                  onClick={() => history.push("/register")}
                >
                  Sign Up
                </Button>
                <Button
                  size="large"
                  className={wallet}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  component={Link}
                  onClick={() => history.push("/login")}
                >
                  Sign In
                </Button>
              </>
            )}{" "}
            {getDrawerChoices()}
          </div>
        </Drawer>

        <div>{femmecubatorLogo}</div>
        <Grid container>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <IconButton
              className={drawericon}
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{ color: "#197ab3", fontSize: "30px" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, scrollID, href }) => {
      return (
        <>
          <ScrollLink
            spy={true}
            smooth={true}
            duration={500}
            {...{
              key: label,

              color: "inherit",
              to: scrollID,
              // component: Link,
              className: menuButton1,
            }}
          >
            <MenuItem className={menuMobile}>{label}</MenuItem>
          </ScrollLink>
        </>
      );
    });
  };

  const femmecubatorLogo = (
    <Box>
      <Link to="/" style={{ textDecoration: "none" }}>
        <Box className={box1}>
          <Box>
            <LogoImgDiv>
              <img src="images/TonyLogo.svg" alt="" />
            </LogoImgDiv>
          </Box>
        </Box>
      </Link>
    </Box>
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href, scrollID }) => {
      return (
        <>
          <ScrollLink
            to={scrollID}
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <Button
              {...{
                key: label,
                color: "inherit",
                to: href,
                component: Link,
                className: menuButton,
              }}
            >
              {label}
            </Button>
          </ScrollLink>
        </>
      );
    });
  };
  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, []);
  console.log(token, "token");
  return (
    <>
      <AppBar
        position={history?.location?.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        style={{ backgroundColor: "#ccc0", border: "none" }}
      >
        <Container
          maxWidth={history?.location?.pathname !== "/" ? "lg" : "fixed"}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose1}
      >
        <MenuItem>
          <Link to="/profile">My Profile</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/user">My Nft</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/resell-nft">Resell Nft</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/create-nft">Create NFT</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/notification">Notification</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/search">Search</Link>
        </MenuItem>
      </Menu>
    </>
  );
}
