import React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Grid,
  Box,
  Typography,
  Container,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import TopBar from "src/layouts/HomeLayout/TopBar.js";
import Footer from "../LoginLayout1/Footer1";
// import Term from "src/views/Auth/Terms-and-condition/Index"
const useStyles = makeStyles((theme) => ({
  content: {
    // marginLeft: "30%",
    backgroundColor: "none",
    // height: "100vh",
    marginTop:'4%',
 
    // overflowX: "auto",
    // [theme.breakpoints.only("lg")]: {
    //   maxWidth: "800px",
    //   // marginLeft:"100px"
    // },
  },
  content1: {
    width: "510px",
    marginTop: "17%",
    borderRadius: "8px",
   
background: "#F4F8FE",
    height: "auto",
    maxHeight: "45rem",
    // overflowX: "auto",
    // [theme.breakpoints.only("lg")]: {
    //   maxWidth: "800px",
    //   // marginLeft:"100px"
    // },

    color: "#000",
  },
  left: {
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  mainClass: {
    marginLeft: "20%",
    [theme.breakpoints.only("lg")]: {
      maxWidth: "665px",
      marginLeft: "70px",
    },
    [theme.breakpoints.only("md")]: {
      // maxWidth:"665px",
      paddingLeft: "40px",
    },
  },

  mainbox: {
    width: "580px",
    height: " 580px",
    marginTop: "12%",
    marginLeft: "-3%",
  
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  mainbox1: {
    marginTop: "18%",
    marginLeft: "-6%",
    width: "639px",
    height: " 450px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  root: {
    backgroundImage: "url(images/backgroundLogin.svg)",
    height:"150vh", 
    backgroundSize: "cover",
    backgroundColor: "#2D2D2D",

    "@media (min-width: 1326px)": {
      "& .MuiContainer-root": {
        paddingLeft: "0",
        paddingRight: "0",
      },
    },
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Box className={classes.root}>
        <div className={classes.Top}>
          <TopBar />
        </div>

        <Container maxWidth="lg">
          <Grid container style={{justifyContent:"center"}}>
            <Box className={classes.content}>
              <Box className={classes.content1}>{children}</Box>
            </Box>
          </Grid>
        </Container>
        {/* {window.location.pathname ==="/term" ? (<>
        <Term/>
      </>) :("")} */}

        {/* 
      <Footer /> */}
      </Box>
    </>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
