import React, { useState, useEffect } from "react";
import "src/scss/main.css";
import { VisibilityOff, Visibility } from "@material-ui/icons";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineLock, AiOutlineEye } from "react-icons/ai";
import {
  useHistory,
  Link as RouterComponent,
  useLocation,
} from "react-router-dom";
import TopBar from "src/layouts/LoginLayout/index.js";
import * as yep from "yup";
import {
  Typography,
  Grid,
  Box,
  Button,
  Dialog,
  TextField,
  IconButton,
  DialogTitle,
  FormControl,
  makeStyles,
  DialogContent,
  InputAdornment,
  DialogContentText,
  DialogActions,
  FormHelperText,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import ApiConfig from "src/config/APICongig";
import axios from "axios";
import ToastMessage from "src/component/CustonToast.js";
// import { toast, ToastContainer } from "react-toastify";
const useStyles = makeStyles((theme) => ({
  buttonbox: {
    padding: "10px 18px",
    width: "432.8px",
    height: "37.4px",
    background: "#269000",
    borderRadius: "7px",
    border:" 3px solid #269000",
    [theme.breakpoints.only("sm")]: {
      maxWidth: "112px",
      fontSize: "9px",
    },
    [theme.breakpoints.only("xs")]: {
      maxWidth: "112px",
      fontSize: "9px",
    },
    "&:hover": {
      background: "#FFFFFF",
      color: "#269000",
      border:" 3px solid #269000",
    },
  },
  loginform: {
    padding: "24px 19px ",
  },
  TextFiled: {
    marginTop: "5%",
    width: "434px",
    height: "48px",
    background: " #FFFFFF",
    justifyContent: "space-between",
    border: "1px solid rgba(0, 0, 0, 0.5)",
    boXShadow: " 0px 0px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "7px",
  },
  signupbox: {
    padding: " 29px 19px",
  },
  textFiled: {
    width: " 434px",
    height: " 48px",
    background: "#FFFFFF",
    border: "1px solid rgba(0, 0, 0, 0.5)",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "7px",
  },
  title: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#343434",
    paddingBottom:"8px",

  },
}));
function Reset(props) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const otp = location.state?.otp;
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [resetToken, setResetToken] = useState("");
  const [isLoading, setLoader] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };



  const [open, setOpen] = useState(false);
  const [type, setType] = useState(false);
  const [message, setMessage] = useState("");

  const handleClose = (x) => {
    setOpen(x);
  };
  const resetPaswordHandler = async (values) => {
    try {
      setLoader(true);
      const email = sessionStorage.getItem("email");
      const res = await axios({
        method: "POST",
        // url: ApiConfig.resetPassword,
        url: `https://nodepune-flashloan.mobiloitte.com/api/v1/forgetPassword?email=${email}`,
        data: {
          newPassword: values.password,
          confirm_Password: values.confirmPassword
        },
        params: {
          email: sessionStorage.getItem("email")
        },
      });

      if (res.data.success) {
        setType("success");
        setOpen(true);
        setMessage(res.data.message);
        // toast.success(res.data.message);
        setLoader(false);
        setOpen(true);
        setTimeout(() => {
          history.push("/login");
        }, 3000);
      } else {
        setType("error");
        setOpen(true);
        setMessage(res.data.message);
        // toast.error(res.data.message);
      }
    } catch (err) {
      // toast.error(err.message)
      setType("error");
      setOpen(true);
      setMessage("Invalid user");
      // toast.error("Invalid user");
      console.log(err);
      setLoader(false);
    }
  };

  return (
    <>
      <ToastMessage
        type={type}
        handleClose={handleClose}
        open={open}
        message={message}
      /> 
    {/* <div>
    <ToastContainer />
  </div> */}
    <TopBar>
      <Grid className="">
        <Box className="loginForm">
          <Box className="signupbox">
            <Grid container direction={"column"}>
              <Grid
                item
                style={{ display: "flex", justifyContent: "center" }}
              ></Grid>

              <Formik
                onSubmit={(values) => resetPaswordHandler(values)}
                initialValues={{
                  password: "",
                  confirmPassword: "",
                }}
                initialStatus={{
                  success: false,
                  successMsg: "",
                }}
                validationSchema={yep.object().shape({
                  password: yep
                    .string()
                    .required("Password is required")
                    .matches(
                      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
                    ),
                  confirmPassword: yep
                    .string()
                    .required("Confirm Password is required")
                    .oneOf([yep.ref("password"), null], "Confirm Password should be match with password")
                    // .oneOf([yep.ref("password"), null], "Your new password should not match old password"),
                })}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  setFieldValue,
                }) => (
                  <Form style={{ padding: "28px 34px " }}>
                    <Grid
                      item
                   
                    >
                      <Box>
                        <label className={classes.title}>
                         New password
                        </label>
                        <TextField
                          style={{ marginTop: "3%" }}
                          className={classes.textFiled}
                          placeholder="New Password"
                          size="small"
                          variant="outlined"
                          fullWidth
                          type={showPassword ? "text" : "password"}
                          value={values.password}
                          name="password"
                          error={Boolean(touched.password && errors.password)}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{
                            maxLength: 16
                          }}
                          InputProps={{
                            className: classes.textfiled,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setShowPassword(!showPassword)}
                                  edge="end"
                                >
                                  <Box className={classes.passsec}>
                                    {showPassword ? (
                                      <AiOutlineEye
                                        style={{
                                          color: "#7A7A7A",
                                          // color: "#7A7A7A",
                                          fontSize: "18px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      />
                                    ) : (
                                      <AiOutlineEyeInvisible
                                        style={{
                                          color: "#7A7A7A",
                                          // color: "#7A7A7A",
                                          fontSize: "18px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      />
                                    )}
                                  </Box>
                                </IconButton>
                              </InputAdornment>
                            ),
                            startAdornment: (
                              <InputAdornment position="start">
                                <Box
                                  style={{
                                    borderRight: "0.5px solid #7A7A7A",
                                  }}
                                >
                                  <AiOutlineLock
                                    style={{
                                      marginLeft: "-2px",
                                      marginRight: "10px",
                                      width: "14px",
                                      color: "#269000",
                                      fontSize: "22px",
                                    }}
                                  />
                                </Box>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <FormHelperText error style={{ fontSize: "12px" }}>
                          {touched.password && errors.password && (
                            <FormHelperText
                              error
                              style={{ marginLeft: "-8%", fontSize: "12 px" }}
                            >
                              <ul
                                style={{
                                  marginTop: "0px",
                                }}
                              >
                                <label>
                                  Must contain characters between 8-16, one uppercase, one
                                  lowercase, one number and one special case
                                  character
                                </label>
                              </ul>
                            </FormHelperText>
                          )}
                        </FormHelperText>
                      </Box>
                    </Grid>
                    &nbsp;
                    <Grid
                      item
                      style={{ marginBottom: "10px", marginTop: "-0px" }}
                    >
                      <FormControl fullWidth>
                        <Box
                          style={{ width: "100%" }}
                          className={classes.loginForm1}
                        >
                          <Typography className={classes.title}>
                            Confirm Password
                          </Typography>
                          
                          <TextField
                            className={classes.textFiled}
                            placeholder="Confirm Password"
                            size="small"
                            variant="outlined"
                            fullWidth
                            type={showPassword1 ? "text" : "password"}
                            value={values.confirmPassword}
                            name="confirmPassword"
                            // placeholder="Confirm your password"
                            error={Boolean(
                              touched.confirmPassword && errors.confirmPassword
                            )}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            inputProps={{
                              maxLength: 16
                            }}
                            InputProps={{
                              className: classes.textfiled,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      setShowPassword1(!showPassword1)
                                    }
                                    edge="end"
                                  >
                                    <Box className={classes.passsec}>
                                      {showPassword1 ? (
                                        <AiOutlineEye
                                          style={{
                                            color: "#7A7A7A",
                                            // color: "#7A7A7A",
                                            fontSize: "18px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        />
                                      ) : (
                                        <AiOutlineEyeInvisible
                                          style={{
                                            color: "#7A7A7A",
                                            // color: "#7A7A7A",
                                            fontSize: "18px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        />
                                      )}
                                    </Box>
                                  </IconButton>
                                </InputAdornment>
                              ),
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Box
                                    style={{
                                      borderRight: "0.5px solid #7A7A7A",
                                    }}
                                  >
                                    <AiOutlineLock
                                      style={{
                                        marginLeft: "-2px",
                                        marginRight: "10px",
                                        width: "14px",
                                        color: "#269000",
                                        fontSize: "22px",
                                      }}
                                    />
                                  </Box>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <FormHelperText
                            error
                            style={{ marginLeft: "1%", fontSize: "12px" }}
                          >
                            {touched.confirmPassword && errors.confirmPassword}
                          </FormHelperText>
                        </Box>
                      </FormControl>
                    </Grid>
                    <Box>
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.buttonbox}
                        type="submit"
                      >
                        Confirm
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
              {/* <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Congrats"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <span style={{ color: "#fff" }}>
                    You have successfully updated your password. Please login
                    now to access your account.
                  </span>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="primary"
                  autoFocus
                  href="/login"
                >
                  LogIn Now
                </Button>
              </DialogActions>
            </Dialog> */}
            </Grid>
          </Box>
        </Box>
      </Grid>
    </TopBar>
    </>
  );
}

export default Reset;
